import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
// import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {
	const ids = [12,13]
	const banque = 'ING'
	const go = `/go/?go=${banque}`
	
	// const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	// const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	// const star3 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	// const star4 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	// const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	// const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis ING : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque ING avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis ING</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis ING</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Banque en ligne</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/ing-logo.png'
			width={250}
			alt='logo ing'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
{/* <hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row> */}
<hr></hr>
<Post>



<h2>Offre ING</h2>
{/* <p>Offre Promo: </p>
<p>Fin de l'offre: </p>
<p>Conditions: </p> */}


<iframe id='videoYT'src="https://www.youtube.com/embed/aDC5ugl87qw" title="avis ING" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>


<h2>Comparatif cartes bancaires</h2>

<div style={{marginLeft: -5}}>
<DataTable row={ids} what='cartesbancaires' id='postTable'/>
<DataTable row={ids} what='cartesbancaires' id='mobileTable'/>

</div>


<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/ing-site.png'
			width={800}
			alt='site ing'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>




			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>En savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	
<hr></hr>

<h2>Frais Bancaire</h2>
<h3>Virements sortants</h3>
<DataTable row={ids} what='virementSortants' id='postTable'/>
<DataTable row={ids} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={ids} what='virementEntrants' id='postTable'/>
<DataTable row={ids} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={ids} what='decouverts' id='postTable'/>
<DataTable row={ids} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={ids} what='irregularites' id='postTable'/>
<DataTable row={ids} what='irregularites' id='mobileTable'/>

<h2>ING Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={ids} what='retraits' id='postTable'/>
<DataTable row={ids} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={ids} what='assurances' id='postTable'/>
<DataTable row={ids} what='assurances' id='mobileTable'/>





<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
